<template>
  <v-app>
    <div v-if="dataJson">
      <div class="row">
        <div class="col-xl-12">
          <v-card
            class="mx-auto rounded-lg ecosistema-con"
            elevation="10"
            height="100%"
            outlined
          >
            <v-card-title>
              <v-avatar size="56">
                <img
                  alt="Concienciación"
                  src="/media/cide/ecosistemas/CON.png"
                />
              </v-avatar>
              <h1 class="ml-3 texto-ecosistema-con">Concienciación</h1>
            </v-card-title>
            <v-card-text>
              <div class="row">
                <div class="col-xl-8" />
                <div class="col-xl-2">Hogares rurales</div>
                <div class="col-xl-2">Empresas rurales</div>
              </div>

              <v-row>
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Grado de preocupación por la contaminación y la emisión de
                    GEI 2021
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[0].rural }}
                  </span>
                  10: Altamente preocupado - 0: Sin preocupación
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[0].empresa }}
                  </span>
                  10: Altamente preocupado - 0: Sin preocupación
                </div>
              </v-row>
              <v-row>
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Grado de preocupación por la contaminación y la emisión de
                    GEI 2022
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[1].rural }}
                  </span>
                  10: Altamente preocupado - 0: Sin preocupación
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[1].empresa }}
                  </span>
                  10: Altamente preocupado - 0: Sin preocupación
                </div>
              </v-row>
              <v-row>
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Grado de preocupación por la contaminación y la emisión de
                    GEI 2023
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2].rural }}
                  </span>
                  10: Altamente preocupado - 0: Sin preocupación
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2].empresa }}
                  </span>
                  10: Altamente preocupado - 0: Sin preocupación
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6">
          <v-card
            class="mx-auto rounded-lg"
            elevation="10"
            outlined
            height="100%"
          >
            <v-card-title>
              Porcentaje de hogares rurales que participan en iniciativas de
              reducción de la huella de carbono
            </v-card-title>
            <v-card-text>
              <v-card-subtitle>
                haz click para saber más información sobre una CA en específico
              </v-card-subtitle>
              <fusioncharts
                type="spain"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="dsMapaINP02"
              />
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-6">
          <v-card
            class="mx-auto rounded-lg"
            elevation="10"
            outlined
            height="100%"
          >
            <v-card-title>
              Interés en la participación en medidas de reducción de la huella
              de carbono en los hogares rurales
            </v-card-title>

            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    color="#e44a00"
                    data-format="json"
                    :data-source="dataJson2[0]"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    color="#e44a00"
                    data-format="json"
                    :data-source="dataJson2[1]"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    color="#e44a00"
                    data-format="json"
                    :data-source="dataJson2[2]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6">
          <v-card
            class="mx-auto rounded-lg"
            elevation="10"
            outlined
            height="100%"
          >
            <v-card-title>
              Interés en la participación en medidas de reducción de la huella
              de carbono de las empresas rurales.
            </v-card-title>

            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    data-format="json"
                    :data-source="dataJson3[0]"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    data-format="json"
                    :data-source="dataJson3[1]"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    data-format="json"
                    :data-source="dataJson3[2]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-6">
          <v-card
            class="mx-auto rounded-lg"
            elevation="10"
            outlined
            height="100%"
          >
            <v-card-title>
              Grado de apoyo recibido por parte de otros agentes en iniciativas
              que permitan reducir la Huella de Carbono de las Empresas Rurales
            </v-card-title>

            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    data-format="json"
                    :data-source="dataJson4[0]"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    data-format="json"
                    :data-source="dataJson4[1]"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    data-format="json"
                    :data-source="dataJson4[2]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-6">
          <v-card
            class="mx-auto rounded-lg"
            elevation="10"
            outlined
            height="100%"
          >
            <v-card-title>
              Grado de apoyo recibido por parte de otros agentes en iniciativas
              que permitan reducir la Huella de Carbono en los hogares rurales
            </v-card-title>

            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    data-format="json"
                    :data-source="dataJson5[0]"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    data-format="json"
                    :data-source="dataJson5[1]"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    data-format="json"
                    :data-source="dataJson5[2]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-6">
          <v-card
            class="mx-auto rounded-lg"
            elevation="10"
            outlined
            height="100%"
          >
            <v-card-title>
              Conocimientos de los Fondos Next Generation para los hogares
              rurales
            </v-card-title>

            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    data-format="json"
                    :data-source="dataJson8[1]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    data-format="json"
                    :data-source="dataJson8[2]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      selectedVariable:
        "Contribución a la descarbonización del Vehículo Eléctrico",
      selectedYear: "2020",
      selectedOption: "Todos",
      chartColumn: {
        type: "column2d",
        width: "100%",
        height: "400",
        dataFormat: "jsonurl",
      },
      chartMultipleColumn: {
        type: "mscolumn2d",
        width: "100%",
        height: "400",
        dataFormat: "jsonurl",
      },
      mapSpain: {
        type: "spain",
        width: "100%",
        height: "300",
        dataFormat: "jsonurl",
      },
      dsMapaINP02: "./data/CON/nacional/mapa_inp02.json",
      // dsNacionalINP01: "./data/CON/nacional_old/inp01.json",
      dsNacionalINP02: "./data/CON/nacional/inp02.json",
      dsNacionalINP05: "./data/CON/nacional/inp05.json",
      dsNacionalINP07: "./data/CON/nacional/inp07.json",
      dataJson: null,
      dataJson2: [],
      dataJson3: [],
      dataJson4: [],
      dataJson5: [],
      dataJson7: [],
      dataJson8: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getJsonData();
  },
  methods: {
    getJsonData() {
      fetch("/data/CON/nacional/data.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson = data;
        });

      fetch("/data/CON/nacional/inp02.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson2 = data;
        });

      fetch("/data/CON/nacional/inp03.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson3 = data;
        });

      fetch("/data/CON/nacional/inp04.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson4 = data;
        });

      fetch("/data/CON/nacional/inp05.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson5 = data;
        });

      // fetch("/data/CON/nacional/inp07.json")
      //   .then((response) => response.json())
      //   .then((data) => {
      //     this.dataJson7 = data;
      //   });

      fetch("/data/CON/nacional/inp08.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson8 = data;
        });
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
</style>
